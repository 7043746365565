MassiveAgreements.$inject = ['$http', 'serverAddress'];

export default function MassiveAgreements($http, serverAddress) {
  return {
    getEntityAgreements,
    postMassiveAgreements
  };

  function getEntityAgreements(prefixUri) {
    return $http.get(
      `${serverAddress.getBaseUrl()}api2/v1/${prefixUri}/`
    );
  }

  function postMassiveAgreements(prefixUri, agreementTypeId, payload) {
    return $http.post(
      `${serverAddress.getBaseUrl()}api2/v1/${prefixUri}/${agreementTypeId}/send-agreement/`,
      payload,
      { headers: { 'Content-Type': 'application/json' } }
    );
  }
}