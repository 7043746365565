MassiveChangesCtrl.$inject = [
  '$scope',
  'Verticals',
  'dataLoader',
  'ClaimState',
  'SalesClaimState',
  'serverAddress',
  '$http',
  '$route',
  'InvestorPortfolio',
  'MassiveAgreements',
  'notifications'
];
export default function MassiveChangesCtrl(
  $scope,
  Verticals,
  dataLoader,
  ClaimState,
  SalesClaimState,
  serverAddress,
  $http,
  $route,
  InvestorPortfolio,
  MassiveAgreements,
  notifications
) {
  var vm = this;

  vm.layoutSettings = $route.current.$$route.layoutSetting;

  vm.uploadFileInputs = {
    verticals: '',
    lawyers: '',
    salesmans: '',
    states: '',
    salesclaimstates: '',
    incentivestates: '',
    costsstates: '',
    externalcourtlawyers: '',
    investorPortfolio: ''
  };

  vm.agreementVerticals = [
    { prefix: 'Banca', uri: 'bank_entity_agreement' },
    { prefix: 'Banca - Revolving', uri: 'revolving_entity_agreement' },
    { prefix: 'Banca - IRPH', uri: 'irph_entity_agreement' }
  ];
  vm.isMassiveAgreementsLoaded = false;
  vm.agreementSelectedVertical = null;
  vm.agreementData = null;
  vm.hasAgreementData = false;
  vm.agreementSelectedType = null;
  vm.agreementComment = '';
  vm.isSubmitAgreementValid = false;

  vm.fileLoaded = false;
  vm.sendButtonEnabled = false;
  vm.isClaimFile = false;
  vm.showdoctypes = false;
  vm.expfileLoaded = false;
  vm.investorsPortfolioLoaded = false;
  vm.investorPortfolioSelected = false;
  vm.assigned_to_portfolio_date = null;
  vm.massive_comment_check = false;
  vm.showoptions = false;
  vm.comment = '';
  vm.comments_is_important = false;
  vm.comments_show_in_docs_tab = false;
  vm.comments_is_crossed_comment = false;

  vm.optionSelected = function(arg) {
    return arg;
  };

  // ng-to-csv config
  vm.csv = {
    content: null,
    header: true,
    headerVisible: true,
    separator: ',',
    separatorVisible: true,
    result: null,
    encoding: 'UTF-8',
    encodingVisible: true,
    accept: '.csv'
  };

  /* Events */
  $scope.$watch('vm.csv.content', function(newValue) {
    if (newValue) {
      var idsArray = newValue.split('\n');
      var res = [];

      // Parse claims ids to number
      for (var i = 0; i < idsArray.length; i++) {
        res.push(idsArray[i]);
      }

      vm.res = res.filter(item => !!item);
      vm.showdoctypes = true;
    }
  });

  // Load selects with the correct staff:
  vm.salesclaimstateList = SalesClaimState.get({}, function() {
    vm.salesclaimstateList = vm.salesclaimstateList.objects;
    var length = vm.salesclaimstateList.length;
    vm.salesclaimstateList[length] = { name: 'NINGUNO', id: '0' };
    // vm.salesclaimstateList[length + 1] = {name: 'NO CAMBIAR', id: null};
  });

  InvestorPortfolio.get().$promise.then(function(response) {
    vm.investorPorfoliosList = response.objects;
  });

  vm.togglestates = togglestates;

  function togglestates() {
    vm.showsalesorstates = !vm.showsalesorstates;
    vm.uploadFileInputs.states = null;
    vm.uploadFileInputs.salesclaimstates = null;
    vm.uploadFileInputs.externalcourtlawyers = null;
  }

  vm.show_incentives = true;
  dataLoader('incentiveStates').$promise.then(function(response) {
    vm.incentivestateList = response.objects;
  });

  $scope.$watch('vm.uploadFileInputs.verticals', function(newValue) {
    if (newValue) {
      vm.showoptions = true;
      vm.lawyersList = [{ first_name: 'NINGUNO', last_name: '', id: '0' }];
      vm.salesmanList = [{ first_name: 'NINGUNO', last_name: '', id: '0' }];
      vm.externalCourtLawyerList = [{ name: 'NINGUNO', id: '0' }];
      vm.statesList = [{ name: 'NINGUNO', id: '0' }];
      vm.costsstatesList = [{ name: 'NINGUNO', id: '0' }];
      switch (newValue.prefix) {
        case 'AE':
          // Lawyer
          dataLoader(['lawyers', 'salesmen', 'externalcourtlawyers_forairlines']).$promise.then(
            function(response) {
              vm.lawyersList.push(...response['lawyers'].objects);
              vm.salesmanList.push(...response['salesmen'].objects);
              vm.externalCourtLawyerList.push(
                ...response['externalcourtlawyers_forairlines'].objects
              );
            }
          );
          ClaimState.get({ segments__id: 1, costs: false }, function(response) {
            vm.statesList.push(...response.objects);
          });
          break;
        case 'BA':
          dataLoader([
            'banklawyers',
            'banksalesmen',
            'externalcourtlawyers_forbanking'
          ]).$promise.then(function(response) {
            vm.lawyersList.push(...response['banklawyers'].objects);
            vm.salesmanList.push(...response['banksalesmen'].objects);
            vm.externalCourtLawyerList.push(...response['externalcourtlawyers_forbanking'].objects);
          });
          ClaimState.get({ segments__id: 2 }, function(response) {
            vm.statesList.push(...response.objects);
          });
          break;
        case 'AC':
          // AccidentLawyer
          dataLoader([
            'accidentlawyers',
            'accidentsalesmen',
            'externalcourtlawyers_foraccidents'
          ]).$promise.then(function(response) {
            vm.lawyersList.push(...response['accidentlawyers'].objects);
            vm.salesmanList.push(...response['accidentsalesmen'].objects);
            vm.externalCourtLawyerList.push(
              ...response['externalcourtlawyers_foraccidents'].objects
            );
          });
          ClaimState.get({ segments__id: 6 }, function(response) {
            vm.statesList.push(...response.objects);
          });
          break;
        case 'LA':
          // LaboralLawyer
          dataLoader([
            'laborallawyers',
            'laboralsalesmen',
            'externalcourtlawyers_forlaboral'
          ]).$promise.then(function(response) {
            vm.lawyersList.push(...response['laborallawyers'].objects);
            vm.salesmanList.push(...response['laboralsalesmen'].objects);
            vm.externalCourtLawyerList.push(...response['externalcourtlawyers_forlaboral'].objects);
          });
          ClaimState.get({ segments__id: 3 }, function(response) {
            vm.statesList.push(...response.objects);
          });
          break;
        case 'SO':
          // SOLawyer
          dataLoader(['solawyers', 'salesmen', 'courtlawyers']).$promise.then(function(response) {
            vm.lawyersList.push(...response['solawyers'].objects);
            vm.salesmanList.push(...response['salesmen'].objects);
            vm.externalCourtLawyerList.push(...response['courtlawyers'].objects);
          });
          ClaimState.get({ segments__id: 5 }, function(response) {
            vm.statesList.push(...response.objects);
          });
          break;
        case 'TE':
          // PhoneLawyer
          dataLoader(['phonelawyers', 'salesmen', 'externalcourtlawyers_forphone']).$promise.then(
            function(response) {
              vm.lawyersList.push(...response['phonelawyers'].objects);
              vm.salesmanList.push(...response['salesmen'].objects);
              vm.externalCourtLawyerList.push(...response['externalcourtlawyers_forphone'].objects);
            }
          );
          ClaimState.get({ segments__id: 4 }, function(response) {
            vm.statesList.push(...response.objects);
          });
          break;
        case 'TA':
          // TaxesLawyer
          dataLoader([
            'taxeslawyers',
            'taxessalesmen',
            'externalcourtlawyers_fortaxes'
          ]).$promise.then(function(response) {
            vm.lawyersList.push(...response['taxeslawyers'].objects);
            vm.salesmanList.push(...response['taxessalesmen'].objects);
            vm.externalCourtLawyerList.push(...response['externalcourtlawyers_fortaxes'].objects);
          });
          ClaimState.get({ segments__id: 7 }, function(data) {
            vm.statesList.push(...data.objects);
          });
          break;
        case 'NE':
          // NegligencesLawyer
          dataLoader([
            'negligenceslawyers',
            'negligencessalesmen',
            'externalcourtlawyers_fornegligences'
          ]).$promise.then(function(response) {
            vm.lawyersList.push(...response['negligenceslawyers'].objects);
            vm.salesmanList.push(...response['negligencessalesmen'].objects);
            vm.externalCourtLawyerList.push(
              ...response['externalcourtlawyers_fornegligences'].objects
            );
          });
          ClaimState.get({ segments__id: 8 }, function(data) {
            vm.statesList.push(...data.objects);
          });
          break;
        case 'GE':
          dataLoader([
            'managementslawyers',
            'managementssalesmen'
          ]).$promise.then(function(response) {
            vm.lawyersList.push(...response['managementslawyers'].objects);
            vm.salesmanList.push(...response['managementssalesmen'].objects);
          });
          ClaimState.get({ segments__id: 10 }, function(data) {
            vm.statesList.push(...data.objects);
          });
          break;
        case 'SG':
          dataLoader([
            'legalserviceslawyers',
            'legalservicessalesmen'
          ]).$promise.then(function(response) {
            vm.lawyersList.push(...response['legalserviceslawyers'].objects);
            vm.salesmanList.push(...response['legalservicessalesmen'].objects);
          });
          ClaimState.get({ segments__id: 11 }, function(data) {
            vm.statesList.push(...data.objects);
          });
          break;
        default:
      }

      dataLoader('costsclaimstates').$promise.then(function(response) {
        vm.costsstatesList.push(...response.objects);
      });
    }
  });

  $scope.$watch(
    'vm.uploadFileInputs',
    function(newValue) {
      if (newValue) {
        if (
          newValue.lawyers ||
          newValue.salesmans ||
          newValue.states ||
          newValue.salesclaimstates ||
          newValue.externalcourtlawyers ||
          newValue.costsstates ||
          newValue.incentivestates ||
          newValue.investorPortfolio
        ) {
          vm.sendButtonEnabled = true;
        } else {
          vm.sendButtonEnabled = false;
        }
      }
    },
    true
  );

  $scope.$watch(
    'vm.selectOption',
    function(optionSelected) {
      if (typeof optionSelected === 'string') {
        vm.showSendButton = false;
        vm.verticalSelected = false;
        vm.documentTypeSelected = true;

        if (optionSelected === 'Acuerdo con entidad') {
          vm.sendButtonEnabled = false; // Prevent other selections bug
          vm.isMassiveAgreementsLoaded = true;
          vm.isClaimFile = false;
          vm.fileLoaded = false;
          vm.expfileLoaded = false;
          vm.investorsPortfolioLoaded = false;
        }

        if (optionSelected === 'Cesión de reclamaciones' || optionSelected === 'Eliminación de cesión') {
          vm.isMassiveAgreementsLoaded = false;
          vm.isClaimFile = false;
          vm.fileLoaded = false;
          vm.expfileLoaded = false;
          vm.investorsPortfolioLoaded = true;
        }

        if (optionSelected === 'Documentos de reclamaciones') {
          vm.verticalsList = Verticals.get({}, function() {});
          vm.isMassiveAgreementsLoaded = false;
          vm.isClaimFile = true;
          vm.fileLoaded = true;
          vm.expfileLoaded = false;
          vm.investorsPortfolioLoaded = false;
        }

        if (optionSelected === 'Documentos de expedientes') {
          vm.verticalsList = Verticals.get({}, function() {});
          vm.isMassiveAgreementsLoaded = false;
          vm.isClaimFile = false;
          vm.fileLoaded = false;
          vm.expfileLoaded = true;
          vm.investorsPortfolioLoaded = false;
        }
      }
    },
    true
  );

  $scope.$watch('vm.uploadFileInputs.investorPortfolio', function(newValue) {
    if (newValue) {
      vm.verticalsList = newValue.allowed_segments_for_investors;
    }
  });
  // Function to send data via ajax-post to the backend (maybe some stuff could be refactored as a service...)
  function postData(data) {
    /* Sample object of what will be sent:
            var dataAjax = {
            "claims":["1","2","7","27","52","77","5566","5567","5898"],
            "vertical":"BA",
            "lawyer":40759
           }; */

    let isError = false;
    let finishedMessage = '';
    var url = null;
    if (vm.investorsPortfolioLoaded) {
      url = serverAddress.getDocumentUrl() + 'common/bulk/portfolio/';
    } else {
      url = serverAddress.getDocumentUrl() + 'common/bulk/upload/';
    }

    // We had to put Content-Type headers, as described here:
    // http://stackoverflow.com/questions/19254029/angularjs-http-post-does-not-send-data
    $http({
      method: 'POST',
      url: url,
      data: data
    })
      .then(function() {
        finishedMessage =
          'El cambio masivo esta en marcha.\nRecibirás un correo electrónico con el resultado.';
        $scope.$broadcast('loading-bar:hide');
      })
      .catch(function(response) {
        finishedMessage = 'Ha ocurrido el siguiente error: ';
        finishedMessage += response.data;
        $scope.$broadcast('loading-bar:hide');
        isError = true;
      })
      .finally(() => {
        notifications.addCurrentView(isError ? 'error' : 'info', finishedMessage);
      });
  }

  // Button to send all data to the backend
  vm.sendData = sendData;
  function sendData() {
    $scope.$broadcast('loading-bar:show');
    var dataToSend = {};

    dataToSend.claims = vm.res;
    dataToSend.vertical = vm.uploadFileInputs.verticals
      ? vm.uploadFileInputs.verticals.prefix
      : null;
    dataToSend.lawyer = vm.uploadFileInputs.lawyers ? vm.uploadFileInputs.lawyers.id : null;
    dataToSend.salesman = vm.uploadFileInputs.salesmans ? vm.uploadFileInputs.salesmans.id : null;
    dataToSend.state = vm.uploadFileInputs.states ? vm.uploadFileInputs.states.id : null;
    dataToSend.email = vm.uploadFileInputs.email ? vm.uploadFileInputs.email : false;
    dataToSend.actions = vm.uploadFileInputs.actions ? vm.uploadFileInputs.actions : false;
    dataToSend.conditions = vm.uploadFileInputs.conditions ? vm.uploadFileInputs.conditions : false;
    dataToSend.salesclaimstate = vm.uploadFileInputs.salesclaimstates
      ? vm.uploadFileInputs.salesclaimstates.id
      : false;
    dataToSend.incentivestate = vm.uploadFileInputs.incentivestates
      ? vm.uploadFileInputs.incentivestates.id
      : null;
    dataToSend.costsstate = vm.uploadFileInputs.costsstates
      ? vm.uploadFileInputs.costsstates.id
      : null;
    dataToSend.externalcourtlawyer = vm.uploadFileInputs.externalcourtlawyers
      ? vm.uploadFileInputs.externalcourtlawyers.id
      : null;
    dataToSend.isclaimfile = vm.isClaimFile;
    dataToSend.investorsPortfolioLoaded = vm.investorsPortfolioLoaded;
    dataToSend.expfileLoaded = vm.expfileLoaded;
    dataToSend.fileLoaded = vm.fileLoaded;
    dataToSend.assigned_to_portfolio_date = vm.assigned_to_portfolio_date;
    dataToSend.investor_id = vm.uploadFileInputs.investorPortfolio.id
      ? vm.uploadFileInputs.investorPortfolio.id
      : null;
    dataToSend.assign = vm.selectOption === 'Cesión de reclamaciones';

    vm.dataToSend = dataToSend;
    postData(vm.dataToSend);
  }

  function postComment(data) {
    /* Sample object of what will be sent:
            var dataAjax = {
            "claims":["1","2","7","27","52","77","5566","5567","5898"],
            "comment":"text",
            "is_important":true,
            "show_in-docs":false,
            "is_crossed_comment":true
           }; */

    let isError = false;
    let finishedMessage = '';
    var url = serverAddress.getDocumentUrl() + 'common/bulk/massivecomment/';

    $http({
      method: 'POST',
      url: url,
      data: data
    })
      .then(function() {
        finishedMessage =
          'El cambio masivo esta en marcha.\nRecibirás un correo electrónico con el resultado.';
        $scope.$broadcast('loading-bar:hide');
      })
      .catch(function(response) {
        finishedMessage = 'Ha ocurrido el siguiente error: ';
        finishedMessage += response.data;
        $scope.$broadcast('loading-bar:hide');
        isError = true;
      })
      .finally(() => {
        notifications.addCurrentView(isError ? 'error' : 'info', finishedMessage);
      });
  }
  vm.sendComment = sendComment;
  function sendComment() {
    $scope.$broadcast('loading-bar:show');
    var dataToSend = {};
    dataToSend.claims = vm.res;
    dataToSend.vertical = vm.uploadFileInputs.verticals
      ? vm.uploadFileInputs.verticals.prefix
      : null;
    dataToSend.comment = vm.comment;
    dataToSend.comments_is_important = vm.comments_is_important;
    dataToSend.comments_is_crossed_comment = vm.comments_is_crossed_comment;
    dataToSend.comments_show_in_docs_tab = vm.comments_show_in_docs_tab;
    dataToSend.isclaimfile = vm.isClaimFile;
    vm.dataToSend = dataToSend;
    postComment(vm.dataToSend);
  }

  // Massive Agreements section
  vm.resetAgreementsFields = () => {
    vm.selectOption = null;
    vm.isMassiveAgreementsLoaded = false;
    vm.hasAgreementData = false;
    vm.agreementSelectedVertical = null;
    vm.agreementSelectedType = null;
    vm.agreementComment = '';
    vm.isSubmitAgreementValid = false;
  };

  vm.updateSubmitButtonState = () => {
    vm.isSubmitAgreementValid = !!vm.agreementSelectedVertical && !!vm.agreementSelectedType;
  };

  vm.agreementVerticalChange = () => {
    MassiveAgreements.getEntityAgreements(vm.agreementSelectedVertical).then(response => {
      vm.agreementData = response.data.objects;
      vm.hasAgreementData = true;
    })
      .catch(function(error) {
        vm.resetAgreementsFields();
        notifications.addCurrentView('error', 'Algo ha salido mal, vuelve a intentarlo más tarde.');
        console.error('Error al obtener los tipos de acuerdo:', error);
      });
  };

  vm.cancelAgreement = () => {
    vm.resetAgreementsFields();
  };

  vm.submitAgreement = () => {
    const payload = {
      claim_ids: vm.res,
      comment: vm.agreementComment
    };

    MassiveAgreements.postMassiveAgreements(
      vm.agreementSelectedVertical,
      vm.agreementSelectedType.id,
      payload
    )
      .then(() => {
        notifications.addCurrentView('success', 'Acuerdo enviado correctamente');
      })
      .catch(function(error) {
        vm.resetAgreementsFields();
        notifications.addCurrentView('error', error);
        console.error('Error al enviar los datos:', error);
      });
  };
}
